import React from 'react';
import cn from 'classnames/bind';
import Container from '@material-ui/core/Container';

import style from './style.scss';

const cx = cn.bind(style);

const About = () => (
  <Container id="about" maxWidth="lg" style={{ background: '#fff' }}>
    <div className={cx('about-container')}>
      <span>ООО &quot;СтройТандем&quot;</span>
      - еще молодая компания, но уже успешно зарекомендовавшая
      себя как надежный и стабильный партнер, в полной мере
      реализующий свои обязательства перед клиентами.
      В их числе есть как крупные предприятия,
      так и небольшие частные фирмы.
      <p>
        Мы является поставщиком полимерных
        (или, как часто их называют, пластиковых, полиэтиленовых,
        пластмассовых, ПЭ, ПНД) трубопроводных систем для наружных и внутренних сетей
        водоснабжения и водоотведения, газораспределения, ГВС и отопления.
        Точность и быстрота выполнения заказов,
        качество материалов, а также конкурентноспособная
        цена на товар, делают нашу компанию достойным партнером в любом начинании.
        В нашем ассортименте только сертифицированная продукция.
      </p>
      <p>
        За короткий период работы мы смогли сформировать сбалансированный портфель производителей,
        который позволяет нам сохранять бесперебойность поставок в любой сезон.
      </p>
      <p>Организовать продажу продукции только на прямую, минуя посредников.</p>
      <p>
        Наши производственно-складские мощности находятся в нашей же собственности.
        Мы не платим аренду, а потому у нас дешевле.
      </p>
      <p>
        Мы всегда рады помочь не только выгодным предложением,
        но и советом по части выбора того или иного материала.
      </p>
      <p>
        Работать с нами выгодно, интересно и надёжно!
        Приглашаем и Вас к долгосрочному и плодотворному сотрудничеству!
      </p>
    </div>
  </Container>
);

export default About;
