import React from 'react';
import NavBar from '../../components/Admin/NavBar';

const Admin = () => (
  <>
    <NavBar />
  </>
);

export default Admin;
